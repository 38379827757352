<template>
  <b-button
    class="m-3 cardsize"
    :variant="variant"
    :disabled="disabled"
    @click="onClick"
  >
    <b-row>
      <b-col class="d-flex">
        <div style="font-size: 30px">
          <b-icon v-if="icon" :icon="icon" />

          <slot v-if="!icon" name="icon" />
        </div>
        <span class="m-2">
          <strong> {{ name }} </strong>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text-left textsize">{{ description }}</p>
      </b-col>
    </b-row>
  </b-button>
</template>

<script>
export default {
  name: "OperationButton",
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    variant: {
      type: String,
      default: () => "primary",
      required: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.cardsize {
  width: 300px;
  height: 100px;
}

.textsize {
  font-size: 14px;
}
</style>
