<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <operation-button
          name="Sincronizar Preços"
          icon="calculator"
          description="Sincronize o cadastro de novos produtos"
          @click="getPrice"
        />

        <operation-button
          name="Sincronizar Status"
          icon="clipboard-check"
          description="Sincronize os status de ativo ou desativo dos produtos"
          @click="getStatus"
        />
        <operation-button
          name="Sincronizar Produtos"
          icon="card-checklist"
          description="Sincronize os cadastros de novos produtos"
          @click="getProduct"
        />
        <operation-button
          name="Informações Base"
          icon="pencil"
          description="Sincronize as informações base dos produtos"
          @click="getInfo"
        />
        <operation-button
          name="Sincronizar Peso"
          icon="box-seam"
          description="Sincronize os pesos dos produtos"
          @click="getWeight"
        />
        <operation-button
          name="Sincronizar Estoque"
          icon="columns"
          description="Sincronize os estoques dos produtos"
          @click="getStock"
        />
        <operation-button
          name="Sincronizar Fabricante"
          icon="building"
          description="Sincronize o fabricante de cada produto"
          @click="getManufacturer"
        />
        <operation-button
          name="Sincronizar Fiscal"
          icon="person-bounding-box"
          description="Sincronize informações fiscais dos produtos"
          @click="getFiscal"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../plugins/http";
import OperationButton from "../components/button/OperationButton.vue";

export default {
  name: "Operations",
  components: { OperationButton },
  methods: {
    ...mapMutations(["setToasted"]),

    async getPrice() {
      await http
        .get("/sync/price")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de preço dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getProduct() {
      await http
        .get("/sync/product")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de cadastro dos novos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getStatus() {
      await http
        .get("/sync/status")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de status dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getWeight() {
      await http
        .get("/sync/weight")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de peso dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getStock() {
      await http
        .get("/sync/stock")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de estoque dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getManufacturer() {
      await http
        .get("/sync/manufacturer")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de fabricantes foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },

    async getFiscal() {
      await http
        .get("/sync/fiscal")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de fiscal foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },

    async getInfo() {
      await http
        .get("/sync/info")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de informações de produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
